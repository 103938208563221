import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { className, isNonEmptyArray, renderText } from 'utils';
import { ArticleDisclosure, CustomArticleColumnCTA, BodyContent, SEO, CTA } from 'components';
import get from 'lodash.get';
import { articlePropTypes } from 'utils/proptypes';
import styles from './contentPage.module.scss';

const ContentPage = ({ data: { article, allTopics, global }, location }) => {
  const {
    content,
    cta,
    hideFooterCta,
    seo,
    topics,
    title,
    articleImage,
    headline,
    footerFeaturedArticles,
  } = article;

  const {
    articleFooterCta: globalArticleFooterCta,
    disclosureText: globalDisclosureText,
    sidebarMenu,
    sidebarMenuTitle,
  } = global;

  const topicList = allTopics.edges.map(({ node }) => node);

  const articleCta = cta ? cta : globalArticleFooterCta;

  const firstTopicWithImage =
    isNonEmptyArray(topics) && topics.find(({ image }) => get(image, 'file.url'));
  const shareImage = articleImage ? articleImage : get(firstTopicWithImage, 'image');

  const firstTopicWithCustomDisclosure =
    isNonEmptyArray(topics) && topics.find(topic => get(topic, 'customDisclosureText'));
  const disclosureText =
    get(firstTopicWithCustomDisclosure, 'customDisclosureText') || globalDisclosureText;

  const getFooterArticles = max => {
    if (footerFeaturedArticles) {
      const list = [];
      footerFeaturedArticles?.forEach(insight => {
        list.push(insight);
      });
      return list.slice(0, max);
    } else {
      return false;
    }
  };

  return (
    <article id="articleBody">
      <Helmet
        bodyAttributes={{
          class: 'has-animation content-page',
        }}
      />
      <SEO {...seo} title={title} shareImage={shareImage} pathname={location.pathname} />
      <div
        {...className(
          styles.contentWrapper,
          shareImage ? styles.withFeaturedImage : '',
          styles.isMicrosite,
        )}
        data-page-type="content">
        {content && (
          <>
            <BodyContent
              content={content}
              location={location}
              topics={topicList}
              hasFeaturedImage={shareImage && true}
              isMicrosite={true}
              showBreadcrumbs={true}
              breadcrumbsLocation={location}>
              <aside className={styles.sidebarNav}>
                <h2>{sidebarMenuTitle}</h2>
                <nav>{renderText(sidebarMenu)}</nav>
              </aside>
            </BodyContent>
            <ArticleDisclosure
              className={styles.articleDisclosure}
              disclosureText={disclosureText}
            />
            {footerFeaturedArticles && (
              <div
                {...className(styles.relatedArticleFooter, styles.contentWrapper, 'component')}
                data-automation-id="related-article-footer">
                <CustomArticleColumnCTA
                  title={headline}
                  items={getFooterArticles(3)}
                  isFormatted={true}
                />
              </div>
            )}
          </>
        )}
        <div id="sidebarBottomLine" />
      </div>
      {!hideFooterCta && <CTA {...articleCta} onFooter />}
    </article>
  );
};

ContentPage.propTypes = {
  data: PropTypes.shape({
    article: articlePropTypes,
    global: PropTypes.object,
    recentFromTopic: PropTypes.object,
    allTopics: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContentPage;

export const query = graphql`
  fragment CPTEASER on ContentfulContentPage {
    title
    slug
    intro {
      intro
    }
    featuredImage {
      title
      description
      file {
        url
      }
    }
    topics {
      name
      image {
        file {
          url
        }
      }
      slug
    }
  }

  query contentPageQuery($slug: String!, $topicSlug: String!) {
    article: contentfulContentPage(slug: { eq: $slug }) {
      title
      slug
      displayIntroOnArticlePage
      intro {
        intro
      }
      topics {
        slug
        name
        image {
          title
          file {
            url
          }
        }
        slug
        customDisclosureText {
          json
        }
      }
      content {
        json
      }
      featuredImage {
        description
        title
        file {
          url
        }
      }
      headline
      footerFeaturedArticles {
        ... on ContentfulContentPage {
          ...CPTEASER
        }
        ... on ContentfulColumnCtaItem {
          ctaType
          headline
          ctaUrl
          label
          labelUrl
          intro {
            intro
          }
          image {
            description
            title
            file {
              url
            }
          }
        }
      }
      cta {
        ...CTA
      }
      hideFooterCta
      seo {
        ...SEO
      }
    }

    allTopics: allContentfulTopic(sort: { fields: name }) {
      edges {
        node {
          name
          slug
        }
      }
    }

    recentFromTopic: allContentfulContentPage(
      limit: 5
      filter: { topics: { elemMatch: { slug: { eq: $topicSlug } } } }
    ) {
      edges {
        node {
          ...CPTEASER
        }
      }
    }

    global: contentfulGlobal {
      articleFooterCta {
        ...CTA
      }
      disclosureText {
        json
      }
      sidebarMenu {
        json
      }
      sidebarMenuTitle
      url
    }
  }
`;
